import Head from 'next/head';
import { Stack, Container, Box } from '@chakra-ui/layout';
import { useRouter } from 'next/router';

import LoginUi from '../containers/LoginUi';
import Logo from '../components/Logo';

function Login() {
  const { query } = useRouter();
  // console.log(query)
  return (
    <>
      <Head>
        <title>登入 | {process.env.NEXT_PUBLIC_APP_NAME}</title>
      </Head>

      <Container
        as={Stack}
        height="100vh"
        alignItems="center"
        justifyContent="center"
        spacing="4"
      >
        <Box width="full" maxWidth="320px">
          <Logo variant="normal" />
        </Box>
        <LoginUi defaultProvider={query.provider} />
      </Container>
    </>
  );
}

export default Login;
